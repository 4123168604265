import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HomePage } from './pages/Home'
import { ManagePurchase } from './pages/ManagePurchase'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from './graphql/client'
import { ConfirmProvider } from 'material-ui-confirm'

const App = () => {
  const apolloClient = useApollo()
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: { autoFocus: true },
          }}
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/purchases/manage/:subscriptionId"
              element={<ManagePurchase />}
            />
          </Routes>
        </ConfirmProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default App
