import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { useMemo } from 'react'

let apolloClient: ApolloClient<NormalizedCacheObject>

export const getApolloClient = () => apolloClient

export const getClientUrl = () => {
  console.log('process.env.API_HOST', process.env.REACT_APP_API_HOST)
  return process.env.REACT_APP_API_HOST
}

function createApolloClient () {
  const apiURL = getClientUrl()
  const httpLink = new HttpLink({
    uri: apiURL,
    credentials: 'same-origin', // login will not work with current authentication method..
    fetchOptions: {
      // mode: 'no-cors', // this sends request in text/plain - which is not accepted by the server
    },
  })

  apolloClient = new ApolloClient({
    link: httpLink, // concat(customHeadersLink, httpLink),
    cache: new InMemoryCache(),
  })
  return apolloClient
}

export function initializeApollo () {
  return apolloClient || createApolloClient()
}

export function useApollo () {
  const store = useMemo(() => initializeApollo(), [])
  return store
}
